import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import Logo from './Logo';

const Navigation = () => {
  const location = useLocation();

  const [expanded, setExpanded] = useState(false);
  const [scrolled, setScrolled] = useState(window.scrollY >= 66);

  const onToggleExpanded = () => {
    setExpanded(!expanded);
  };

  const changeBackground = () => {
    if (window.scrollY >= 66) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change Logo
    window.addEventListener('scroll', changeBackground);
  }, []);

  useEffect(() => {
    setExpanded(false);
  }, [location]);

  return (
    <>
      <Navbar
        expand="sm"
        className={`fixed-top shadow-0 ${scrolled ? 'mask-custom-scrolled navbar-scroll' : 'mask-custom'}`}
        expanded={expanded}
        onToggle={onToggleExpanded}
      >
        <Container>
          <Link className="navbar-brand" to="/">
            <Logo />
          </Link>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Link className="nav-link" to="/">
                Home
              </Link>
              <Link className="nav-link" to="/boek-een-bosbad">
                Boek een bosbad
              </Link>
              <Nav.Link as={Link} href="/">
                Over ons
              </Nav.Link>
              <Nav.Link as={Link} href="/">
                Voor leden
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Navigation;
